import { INGPropertiesEditor } from "../library/NGFieldExtensions";
import {
  FlexLayout,
  Form,
  Control,
  PropertiesEditor,
  InputField,
  Label,
  AccordionLayout,
  Checkbox,
  List,
  Button,
  ButtonGroup,
  LayoutItem,
  DatePicker,
  Maybe,
} from "../../resolvers-types";
import { isEmpty, isNil } from "lodash-es";
import {
  getDefaultContext,
  getState,
  setupHandlers,
  setupLocalState,
  setupServices,
  updateItemContext,
} from "../library/dataService";
import { useComputed, useSignalEffect, useSignal } from "@preact/signals-react";
import { getTestId, isNullOrEmpty, splitCamelCase } from "../library/utils";
import { Box, ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material";
import NGForm from "./NGForm";
import { FieldDefinition, TypeWithProperties } from "../gqlplugins/generateEditorJSON";
import jsonData from "../propertiesEditor/editorProperties.json";
import fieldDefaults from "../propertiesEditor/fieldProps.json";
import fieldPropsGroups from "../propertiesEditor/fieldPropsGroups.json";
import { contextMenuForState, headerButtonsByType } from "./PropertiesEditorHelpers";
import { getTypename } from "../library/metadataUtils";
import propertiesEditorPlaceholder from "./PropertiesEditorPlaceholder.png";
import useResizable from "../hooks/useResizable";
import { memo } from "react";

const NGPropertiesEditor = memo(({ config, context }: INGPropertiesEditor) => {
  type FieldRenderFunction = (
    id: string,
    labelId: string,
    field: FieldDefinition,
    parentId: string,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) => void;

  const currentContext = updateItemContext(context, config);

  const fieldRenderFunctions: { [key: string]: FieldRenderFunction } = {
    boolean: addCheckBox,
    string: addInputField,
  };

  const services = [
    {
      Name: "styles-query",
      Service: "styles.query",
      Require: "State.Typename",
      Fields: [
        {
          Name: "ThemeName",
          //Required: true,
          Bindings: {
            Value: "State.NGTheme.Name || 'communify'",
          },
        },
        {
          Name: "Filter",
          Value: {},
          Bindings: {
            Value: "{ Typename: State.Typename }",
            // Value: `{ Typename: '${tn}' }`,
          },
        },
      ],
      Bindings: {
        "State.Styles": "Result.Items || []",
      },
    },
    {
      Name: "formats-query",
      Service: "formats.query",
      Require: "State.Typename",
      Fields: [
        {
          Name: "SiteName",
          //Required: true,
          Bindings: {
            Value: "State.NGSite.Name || 'communify'",
          },
        },
        {
          Name: "Filter",
          //Required: true,
          Value: {},
          Bindings: {
            Value: "{ Typename: State.Typename }",
            // Value: `{ Typename: '${tn}' }`,
          },
        },
      ],
      Bindings: {
        "State.Formats": "Result.Items || []",
      },
    },
    {
      Name: "GetComponentFromReferenceId",
      Service: "project.component.get",
      Require: "State.PropertiesEditorData.ReferenceType=='Component'",
      Fields: [
        {
          Name: "Id",
          Required: true,
          Bindings: {
            Value: "State.PropertiesEditorData.ReferenceId",
          },
        },
      ],
      Bindings: {
        "State.ReferenceComponent": "Result.Entity",
      },
      Actions: [
        {
          Trigger: "onSuccess",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false, //ReferenceComponentInputsAsGrid
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.ReferenceComponentInputs": "reduceNGProperties(Event.Result.Entity.Inputs)",

                      // "State.ReferenceComponentInputs":
                      //   "Object.entries(State.ReferenceComponent.Inputs).reduce(function (acc, entry) { \
                      //   acc[entry[0]] = entry[1].DefaultValue || null; \
                      //   acc; \
                      // }, {});",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "JSONToNameValuePair",
                  },
                  {
                    Name: "Options",
                    Value: {
                      SingleRecord: true,
                    },
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      InputData: "merge(State.ReferenceComponentInputs,State.PropertiesEditorData.Inputs || {})",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.ReferenceComponentInputsAsGrid": "Event.TransformedData.Rows",
                      "State.ReferenceComponentInputsAsGridColumns": "Event.TransformedData.Columns",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      Name: "SaveStyleClass",
      Service: "styles.merge",
      Trigger: "Action",
      Fields: [
        {
          Name: "ThemeName",
          Bindings: {
            Value: "State.NGTheme.Name || 'communify'",
          },
        },
        {
          Name: "Style",
          Value: {},
          Bindings: {
            Value:
              "{ Key: State.StyleClassPopup.Name, Qualifiers: State.StyleClassPopup.Qualifiers, Value: State.StyleClassPopup.Style }",
          },
        },
      ],
    },
    {
      Name: "GetStyle",
      Service: "styles.get",
      Require: "State.StyleClassPopup.Name",
      Fields: [
        {
          Name: "ThemeName",
          //Required: true,
          Bindings: {
            Value: "State.NGTheme.Name || 'communify'",
          },
        },
        {
          Name: "StyleName",
          Required: true,
          Value: null,
          Bindings: {
            Value: "State.StyleClassPopup.Name",
          },
        },
      ],
    },
  ];

  const fieldsToIgnore = [
    "Items",
    //"ModalPopups",
    "ComponentReferenceIds",
    //"Services",
    "Enabled",
    // "Layout",
    // "State",
    "isValid",
    "getData",
    "Bindings",
    "CanBeDesigned",
    "InDesignMode",
  ];

  const readOnlyTypes = ["ListQuickFilter", "ListExportButton"];

  const fieldsToIgnoreForLayout = ["Loading", "Enabled", "Layout"];
  const layoutsToIgnore = ["Page", "SimpleContainer", "FlexContainer", "Repeater", "TabContainer"];

  const _cacheFields = {};

  const formId = config.FormId ?? "NGPropEditor";

  const emptyForm: Form = {
    Typename: "Form",
    Id: "emptyForm",
    UniqueName: "emptyForm",
  };

  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Enabled: useSignal(config.Enabled ?? true),
      // Components: useSignal(form.Items || []),
      Resizable: useSignal(config.Resizable ?? false),
      Data: useSignal(config.Data ?? {}),
      ViewMode: useSignal(config.ViewMode ?? "All"),
      HeaderItems: useSignal(config.HeaderItems ?? []),
      FieldTypes: useSignal(config.FieldTypes ?? jsonData),
      SearchPropInput: useSignal(config.SearchPropInput ?? ""),
      ToggleExpanded: useSignal(config.ToggleExpanded ?? ""),
      // InDesignMode: useSignal(form.InDesignMode || false),
    },
    currentContext
  );

  const { resizable } = useResizable(local.Resizable.value);

  const handlers = setupHandlers(config, currentContext);
  setupServices({ Services: services }, currentContext); // AA-TODO: Refactor this to use component

  function isEmptyData() {
    return isNil(local.Data.value) || isEmpty(local.Data.value);
  }

  useSignalEffect(() => {
    // TK-TODO: this is wrong
    const { global, state } = getState(getDefaultContext());

    const tn = getTypename(local.Data.value);
    if (isNullOrEmpty(tn)) return;

    if (!isNil(state["Typename"])) state["Typename"].value = tn;
  });

  const form = useComputed<Form>(() => {
    const localData = local.Data.value;

    if (isEmptyData() && !config.DefaultTypeName) {
      return emptyForm;
    }

    const f = createForm(local.Data.value, config, local.ViewMode.value, local.SearchPropInput.value, local);

    return f;
  });

  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            height: "100%",
            width: "100%",
            marginTop: "8px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: "100%",
            "input.MuiAutocomplete-input.MuiInputBase-input": {
              minWidth: "30px",
            },
          },
        },
      },
    },
  });

  return (
    <>
      {local.Visible.value &&
        resizable(
          <ThemeProvider theme={theme}>
            <Box data-testid={getTestId(config)} data-type={config.__typename} sx={config.Style}>
              {form.value.Id == emptyForm.Id && (
                <Box sx={{ margin: "30px" }}>
                  <img src={propertiesEditorPlaceholder} alt="Properties Editor" loading="lazy" />
                </Box>
              )}

              {form.value.Id != emptyForm.Id && <NGForm config={form.value} context={currentContext} />}
            </Box>
          </ThemeProvider>
        )}
    </>
  );

  function addFormActionButton(
    config: PropertiesEditor,
    renameEvent: string,
    controls: Control[],
    id: string,
    icon: string,
    layoutItems: string[],
    Bindings?: unknown
  ) {
    const onClickActions = config.Actions?.filter((action) => action?.Trigger === renameEvent) // Filter to only include onSave triggers
      .map((action) => ({ ...action, Trigger: "onClick" })); // Map to rename the trigger

    controls.push({
      __typename: "Button",
      Id: id,
      Bindings,
      // Name: id,
      TopIcon: { IconName: icon },
      Actions: onClickActions,
      Classes: ["button-tertiary-small"],
    } as Button);

    layoutItems.push(id);
  }

  function addBindingButton(
    id: string,
    controls: Control[],
    bindingField: string,
    showBindingKey: string,
    showBindingValue: string
  ) {
    controls.push({
      __typename: "Button",
      Id: id,
      // Name: id,
      Classes: ["button-tertiary-small"],
      TopIcon: { IconName: "Link" },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      [showBindingKey]: `!(${showBindingValue})`,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    });
  }

  //onDelete

  function addInputField(
    id: string,
    labelId: string,
    field: FieldDefinition,
    parentId: string,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean,
    label?: string
  ) {
    const control = {
      __typename: "InputField",
      Id: id,
      Name: field.name, // Type: '${field.type}'
      Size: "small",
      Placeholder: label ?? "",
      Classes: ["prop-editor-input"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
      Debounce: 5000,
    } as InputField;

    addControl({ field, controls, control, layouts, id, hasBindings });
  }
  function addDatePicker(
    id: string,
    labelId: string,
    field: FieldDefinition,
    parentId: string,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean,
    label?: string
  ) {
    const control = {
      __typename: "DatePicker",
      Id: id,
      Name: field.name,
      Size: "small",
      Placeholder: label ?? "",
      Classes: ["prop-editor-input"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    } as DatePicker;

    addControl({ field, controls, control, layouts, id, hasBindings });
  }

  type layoutItemsProps = {
    leftLayout?: string[];
    rightLayout: string[];
    bindingLayout?: string[];
  };
  type LayoutsProps = {
    [field: string]: {
      ids: layoutItemsProps[];
      layoutType?: string;
      classes?: string;
      bindingFirst?: boolean;
    };
  };

  type addControlProps = {
    field: FieldDefinition;
    controls: Control[];
    control: any;
    layouts: LayoutsProps;
    id: string;
    hasBindings: boolean;
    helperControls?: any[];
    group?: string;
    label?: string;
    bindingFirst?: boolean;
    classes?: string;
  };

  function addControl({
    field,
    controls,
    control,
    layouts,
    id,
    hasBindings,
    helperControls,
    group,
    label,
    bindingFirst,
    classes,
  }: addControlProps) {
    const newLayoutItems: layoutItemsProps = {
      leftLayout: [],
      rightLayout: [],
      bindingLayout: [],
    };

    if (config.FieldsReadOnly?.includes(field.name)) control.Disabled = true;

    if (
      control.__typename === "InputField" ||
      control.__typename === "Button" ||
      control.__typename === "MultiSelect" ||
      control.__typename === "DatePicker" ||
      control.__typename === "CodeEditor"
    ) {
      const controlLabel = {
        __typename: "Label",
        Id: `${control.Id}-label`,
        Value: label ?? splitCamelCase(control.Name),
        Classes: ["control-label"],
      } as Label;

      if (control.__typename === "CodeEditor") {
        controlLabel.Style = { alignSelf: "baseline" };
        control.Classes;
      }

      controls.push(controlLabel);
      newLayoutItems.leftLayout?.push(controlLabel.Id);
    }

    controls.push(control);
    newLayoutItems.rightLayout.push(id);

    if (helperControls) {
      helperControls.forEach((helperControl) => {
        controls.push(helperControl);
        newLayoutItems.rightLayout.push(helperControl);
      });
    }

    if (hasBindings) {
      const bindgingButtonId = `${id}_binding_button`;
      const bindgingInputId = `${id}_binding_input`;
      const showBindingKey = `State.PropertiesEditorState[State.PropertiesEditorData.Id]['${field.name}'].ShowBinding`;
      const showBindingValue = `${showBindingKey} == null ? Form['Bindings.${field.name}'] != null : ${showBindingKey}`;

      const bindingInputControl = {
        __typename: "InputField",
        Id: bindgingInputId,
        Name: `Bindings.${field.name}`, // Type: '${field.type}'
        Label: `'${splitCamelCase(field.name)}' binding expression`,
        Size: "small",
        Visible: false,
        Bindings: {
          Visible: showBindingValue,
        },
        Actions: [
          {
            Trigger: "onChange",
            CommandSet: {},
          },
        ],
      } as InputField;

      controls.push(bindingInputControl);
      newLayoutItems.bindingLayout?.push(bindgingInputId);
      newLayoutItems.rightLayout.push(bindgingButtonId);

      addBindingButton(bindgingButtonId, controls, field.name, showBindingKey, showBindingValue);
    }

    let fieldGroup = group ?? field.directives?.editorProps?.group;

    if (field.group) fieldGroup = field.group;

    if (isNil(fieldGroup)) {
      const props = getFieldProps(field.name);

      if (props && props.Group) fieldGroup = props.Group;
      else fieldGroup = "Other";
    }

    if ((config.UseFieldGroups && fieldGroup) || (control.__typename === "PropertiesEditor" && fieldGroup)) {
      if (layouts[fieldGroup]) {
        layouts[fieldGroup].ids.push(newLayoutItems);
      } else {
        layouts[fieldGroup] = {
          ids: [newLayoutItems],
          layoutType: "AccordionLayout",
          bindingFirst,
          classes,
        };
      }
    } else {
      layouts[id] = { ids: [newLayoutItems], bindingFirst, classes };
    }
  }

  function addCheckBox(
    id: string,
    labelId: string,
    field: FieldDefinition,
    parentId: string,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      __typename: "Checkbox",
      Sequence: 71,
      Name: field.name,
      Label: splitCamelCase(field.name),
      Id: id,
      LabelPlacement: "start",
      Classes: ["prop-editor-checkbox"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    } as Checkbox;

    addControl({ field, controls, control, layouts, id, hasBindings });
  }

  function addMultiSelectForFormatName(
    id: string,
    labelId: string,
    field: FieldDefinition,
    parentId: string,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      __typename: "MultiSelect",
      Name: field.name,
      Id: id,
      Label: "",
      FreeSolo: true,
      Multiple: false,
      LabelExpression: "Name",
      ValueExpression: "Name",
      Bindings: {
        MultiSelectPossibleValues: "State.Formats",
      },
      Classes: ["prop-editor-multiselect"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    const button = {
      __typename: "Button",
      Id: `${id}-add`,
      // Name: `${id}-add`,
      TopIcon: { IconName: "Edit" },
      Classes: ["button-tertiary-small"],
      Bindings: {
        Disabled: `!Form.${field.name}`,
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
        {
          Trigger: "onClick",
          CommandSet: {
            Id: "0x4453",
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.PopupTypename": "State.Typename",
                      "State.PopupFormats": `Form.${field.name}`,
                      "State.PopupSelectedFormat": `Form.${field.name}`,
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "OpenModalPopup",
                },
                Parameters: [
                  { Name: "Title", Value: "Edit Format" },
                  { Name: "ModalPopupId", Value: "EditFormatsPopup" },
                ],
              },
            ],
          },
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id,
      hasBindings,
      helperControls: [button],
    });
  }

  function addMultiSelectForClassSet(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean,
    group?: string,
    label?: string
  ) {
    const control = {
      __typename: "MultiSelect",
      Name: field.name,
      Id: id,
      Label: "",
      FreeSolo: true,
      Multiple: true,
      LabelExpression: "Key",
      ValueExpression: "Key",
      Bindings: {
        MultiSelectPossibleValues: "State.Styles",
      },
      Classes: ["prop-editor-multiselect", "prop-editor-multiselect-with-button"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    const button = {
      __typename: "Button",
      Id: `${id}-add`,
      // Name: `${id}-add`,
      Classes: ["button-tertiary-small"],
      TopIcon: { IconName: "Edit" },
      Bindings: {
        Disabled: `!Form.${field.name}[0]`,
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
        {
          Trigger: "onClick",
          CommandSet: {
            Id: "0x4453",
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.PopupTypename": "State.Typename",
                      "State.PopupStyles": `Form.${field.name}`,
                      "State.PopupSelectedStyle": `Form.${field.name}[0]`,
                      "State.PopupSelectedClass": `['.', Form.${field.name}[0]].join('')`,
                      "State.StyleDetails": "State.NGTheme.Styles[State.PopupSelectedClass]",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "OpenModalPopup",
                },
                Parameters: [
                  { Name: "Title", Value: "Edit Style" },
                  { Name: "ModalPopupId", Value: "EditStylesPopup" },
                ],
              },
            ],
          },
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id,
      hasBindings,
      helperControls: [button],
      group,
      label,
    });
  }

  function addChipEditor(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      __typename: "MultiSelect",
      Id: id,
      Name: field.name,
      Label: "",
      FreeSolo: true,
      Multiple: true,
      MultiSelectPossibleValues: field.directives?.recommendedValues?.values || [],
      Classes: ["prop-editor-multiselect"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({ field, controls, control, layouts, id, hasBindings });
  }

  function addMultiSelect(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      __typename: "MultiSelect",
      Id: id,
      Name: field.name,
      Label: "",
      MultiSelectPossibleValues: field.directives?.recommendedValues?.values || [],
      Classes: ["prop-editor-multiselect"],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({ field, controls, control, layouts, id, hasBindings });
  }

  function addPropertiesEditor(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      __typename: "PropertiesEditor",
      Id: id,
      IgnoreAncillaryButtons: true,
      Actions: [
        {
          Trigger: "onSave",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      [`State.NGForm['${formId}']['${field.name}']`]: "Form",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "TriggerAction",
                },
                Parameters: [
                  {
                    Name: "Trigger",
                    Value: "onSave",
                  },
                  {
                    Name: "TargetId",
                    Value: formId,
                  },
                  {
                    Name: "Form",
                    Value: formId,
                  },
                ],
              },
            ],
          },
        },
      ],
      DefaultTypeName: "Grouping",
      // Name: field.name,
      Bindings: {
        Data: `FormData.${field.name}`,
      },
      Visible: true,
      FieldsReadOnly: [],
      FormId: `${field.name}`,
    };

    addControl({ field, controls, control, layouts, id, hasBindings });
  }

  function addModalPopupsEditor(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean,
    parentId: string
  ) {
    //NGModalPopupEditor

    const control = {
      Id: `${parentId}-modalpopup-editor`,
      __typename: "ModalPopupEditor",
      Label: "Modal Popup Editor",
      Name: field.name,
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field: {
        name: control.Name,
        type: "ModalPopupEditor",
      },
      controls,
      control,
      layouts,
      id: control.Id,
      hasBindings: false,
      group: "Modal Popups",
    });

    // const codeEditorId = `${id}-code-editor`;

    // const control = {
    //   Id: codeEditorId,
    //   __typename: "CodeEditor",
    //   Label: splitCamelCase(field.name),
    //   Name: field.name,
    //   MultiLine: true,
    //   NumberOfLines: 10,
    //   Height: "120px",
    //   Width: "100%",
    //   Actions: [
    //     {
    //       Trigger: "onChange",
    //       CommandSet: {},
    //     },
    //   ],
    // };

    // addControl({ field, controls, control, layouts, id: codeEditorId, hasBindings });
  }

  function addCodeEditor(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const codeEditorId = `${id}-code-editor`;

    const control = {
      Id: codeEditorId,
      __typename: "CodeEditor",
      Label: splitCamelCase(field.name),
      Name: field.name,
      MultiLine: true,
      NumberOfLines: 10,
      Height: "120px",
      Width: "100%",
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id: codeEditorId,
      hasBindings,
    });
  }
  function addBindingsEditor(id: string, field: FieldDefinition, controls: Control[], layouts: LayoutsProps) {
    const controlId = `${id}-binding-editor`;

    const control = {
      Id: controlId,
      __typename: "BindingEditor",
      Title: splitCamelCase(field.name),
      Name: field.name,
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id: controlId,
      hasBindings: false,
      classes: "prop-editor-binding-layout",
    });
  }

  function addTypedBindingsEditor(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    type: string
  ) {
    const controlId = `${id}-binding-editor`;

    const control = {
      Id: controlId,
      __typename: "TypedBindingEditor",
      Title: splitCamelCase(field.name),
      Type: type,
      Name: field.name,
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id: controlId,
      hasBindings: false,
      classes: "prop-editor-binding-layout",
    });
  }

  function addActionEditor(controls: Control[], layouts: LayoutsProps, parentId: string, hasBindings: boolean) {
    const getAction = (trigger?, form?) => {
      return {
        Trigger: "onClick",
        CommandSet: {
          Id: "editActionsButton",
          FirstCommandId: "0",
          ExecuteCommandsInParallel: false,
          Commands: [
            {
              Id: "0",
              Instruction: {
                Name: "SetState",
              },
              Parameters: [
                {
                  Name: "Bindings",
                  Value: {
                    "State.ActionEditor": `{ Actions: ${
                      form ?? "FormData.Actions"
                    } || [], Trigger: ${trigger}, Form: '${formId}' }`,
                  },
                },
              ],
              NextCommandIdOnSuccess: "1",
            },
            {
              Id: "1",
              Instruction: {
                Name: "OpenModalPopup",
              },
              Parameters: [
                { Name: "Title", Value: "Edit Actions" },
                { Name: "ModalPopupId", Value: "EditActionsPopup" },
              ],
            },
          ],
        },
      };
    };

    const editActionsButton = {
      __typename: "Button",
      Id: `${parentId}-action-editor`,
      Name: "Actions",
      Label: "Edit Actions",
      Style: { width: "100% !important", alignSelf: "center" },
      Actions: [getAction()],
      Classes: ["button-tertiary-large"],
    };

    const actionRepeater = {
      __typename: "Repeater",
      Id: `${parentId}-action-repeater`,
      ExcludeFromForm: true,
      Variant: "List",
      Bindings: {
        Rows: "Form.Actions",
      },
      Content: {
        __typename: "SimpleContainer",
        Id: "notesByReviewerForm",
        UniqueName: "notesByReviewerForm",
        Bindings: {
          Visible: "Repeater.Row.CommandSet.Commands.length > 0",
        },
        Style: {
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        },
        Items: [
          {
            __typename: "Label",
            Id: `${parentId}-action-label`,
            Bindings: {
              Value: "FormData.Trigger",
            },
          },
          {
            __typename: "Button",
            Id: `${parentId}-action-editor`,
            Name: "Actions",
            Label: "",
            Actions: [getAction("FormData.Trigger", `Global.NGForm['${formId}'].Actions`)],
            TopIcon: { IconName: "Edit" },
            Classes: ["button-tertiary-small"],
          },
        ],
      },
    };

    const control = {
      __typename: "SimpleContainer",
      Id: "actionEditorContainer",
      UniqueName: "actionEditorContainer",
      Style: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      Items: [editActionsButton, actionRepeater],
    };

    addControl({
      label: "",
      field: {
        name: "Actions",
        type: "ActionEditor",
      },
      controls,
      control,
      layouts,
      id: control.Id,
      hasBindings: hasBindings,
      group: "Navigation and Action Controls",
    });
  }
  function addCellLayoutEditor(controls: Control[], layouts: LayoutsProps, parentId: string, hasBindings: boolean) {
    const control = {
      Id: `multi-cell-editor`,
      __typename: "GridEditor",
      Label: "Cell Components",
      Name: "CellRendererItems",
      UseFormDialog: true,
      Bindings: {
        Data: "State.GridEditor.State",
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
        {
          Trigger: "onSelectRenderer",
          CommandSet: {
            Id: "editActionsButton",
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.CellLayout": "Event.Column",
                      "State.CellLayoutState": "Event.Column",
                      "State.CellLayoutTypename": "Event.Column.__typename",
                      "State.CellLayoutIndex": "Event.Index",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "OpenModalPopup",
                },
                Parameters: [
                  { Name: "Title", Value: "Cell Layout Editor" },
                  { Name: "ModalPopupId", Value: "EditCellLayoutPopup" },
                ],
              },
            ],
          },
        },
      ],
    };

    addControl({
      field: {
        name: "CellLayout",
        type: "CellLayoutEditor",
      },
      controls,
      control,
      layouts,
      id: control.Id,
      hasBindings: hasBindings,
      group: "Cell State and Customization",
    });
  }

  function addStyleEditor(
    controls: Control[],
    layouts: LayoutsProps,
    data: any,
    parentId: string,
    hasBindings: boolean,
    fieldName?: string
  ) {
    const control = {
      Id: `${fieldName ? `${fieldName}-${parentId}-style-editor` : `${parentId}-style-editor`}`,
      __typename: "StyleEditor",
      Label: "Style Editor",
      Name: `${fieldName ? `${fieldName}.Style` : "Style"}`,
      StyleClassPopup: {},
      ShowSaveClass: true,
      Bindings: {
        Layout: "State.PropertiesEditorData",
        StyleClassPopup: "State.StyleClassPopup",
        Type: "State.PropertiesEditorTypeName",
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
        {
          Trigger: "onSaveStyleClass",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.StyleClassPopup":
                        "{ 'Name': Event.Name, 'Qualifiers': Event.Qualifiers, Style: Event.Style, 'Open': true }",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "CallService",
                },
                Parameters: [
                  {
                    Name: "ServiceName",
                    Value: "GetStyle",
                  },
                ],
                NextCommandIdOnSuccess: "1a",
                NextCommandIdOnFailure: "2",
              },
              {
                Id: "1a",
                Instruction: {
                  Name: "ShowMessage",
                },
                Parameters: [
                  {
                    Name: "Message",
                    Value: "This style already exists.  If you continue it will overwrite it.  Continue?",
                  },
                  {
                    Name: "Title",
                    Value: "Save Style",
                  },
                  {
                    Name: "ShowOkButton",
                    Value: true,
                  },
                  {
                    Name: "ShowCancelButton",
                    Value: true,
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "CallService",
                },
                Parameters: [{ Name: "ServiceName", Value: "SaveStyleClass" }],
                NextCommandIdOnSuccess: "3",
              },
              {
                Id: "3",
                Instruction: {
                  Name: "SendMessageToChild",
                },
                Parameters: [
                  {
                    Name: "IFrame",
                    Value: "EditorIFrame",
                  },
                  {
                    Name: "Type",
                    Value: "stylesUpdated",
                  },
                ],
                NextCommandIdOnSuccess: "4",
              },
              {
                Id: "4",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "Form.Classes": "addToArray(Form.Classes,State.StyleClassPopup.Name)",
                      "Form.Style": "{}",
                      "State.StyleClassPopup": "{ 'Open': false }",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "5",
              },
              {
                Id: "5",
                Instruction: {
                  Name: "TriggerAction",
                },
                Parameters: [
                  {
                    Name: "Trigger",
                    Value: "onSave",
                  },
                  {
                    Name: "TargetId",
                    Value: "PropertiesEditor/A/1",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      Event: "Form.Items",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "6",
              },
              {
                Id: "6",
                Instruction: {
                  Name: "CallService",
                },
                Parameters: [
                  {
                    Name: "ServiceName",
                    Value: "styles-query",
                  },
                ],
              },
            ],
          },
        },
      ],
    };

    addControl({
      field: {
        name: control.Name,
        type: "StyleEditor",
      },
      controls,
      control,
      layouts,
      id: control.Id,
      hasBindings: hasBindings,
      group: fieldName || "Style Editor",
      bindingFirst: true,
      classes: "style-editor",
    });

    // this in truth is not needed as the parent container is the one with the style

    // const idInputField = `${id}_if`;

    // const inputField = {
    //   Visible: true,
    //   __typename: "CodeEditor",
    //   Id: idInputField,
    //   Name: `${field.name}.Style`, // Type: '${field.type}'
    //   Height: "100px",
    //   Style: {
    //     height: "100px",
    //   },
    //   // Bindings: {
    //   //   Value: "State.SampleData",
    //   // },
    // } as CodeEditor;

    // const stylesField: FieldDefinition = {
    //   name: `${field.name}.Style`,
    //   type: "CodeEditor",
    // };

    // addControl(labelId, stylesField, controls, inputField, layouts, idInputField, false, [], "Layout Tools", "Style");

    // const idclassSet = `${id}_cs`;

    // const classSet: FieldDefinition = {
    //   name: `${field.name}.Classes`,
    //   type: "ClassSet",
    // };
    // addMultiSelectForClassSet(
    //   idclassSet,
    //   labelId,
    //   classSet,
    //   parentId,
    //   controls,
    //   layouts,
    //   hasBindings,
    //   [],
    //   "Layout Tools",
    //   "Classes"
    // );
  }

  function addButtonSelector(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    // SimpleContainer
    // - Add: Visible if not there
    // -   PropertiesEditor: Visible if there
    // - Delete: Visible if there

    const controlId = `${field.name}-properties-editor`;

    const control = {
      Id: id,
      __typename: "PropertiesEditor",
      DefaultTypeName: "PropertiesEditor",
      Label: splitCamelCase(field.name),
      Name: controlId,
      DefaultName: controlId,
      FormId: `${controlId}-form`,
      Styles: { "> .MuiStack-root:first-child": { gap: "1rem" } },
      Bindings: {
        Data: `FormData.${field.name}`,
      },
      Actions: [
        {
          Trigger: "onSave",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      [`State.NGForm['${formId}']['${field.name}']`]: "Form",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "TriggerAction",
                },
                Parameters: [
                  {
                    Name: "Trigger",
                    Value: "onSave",
                  },
                  {
                    Name: "TargetId",
                    Value: formId,
                  },
                ],
              },
            ],
          },
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id,
      hasBindings,
      group: "Button",
    });
  }

  function addIconSelector(
    id: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      Id: id,
      __typename: "IconSelector",
      Label: splitCamelCase(field.name),
      Name: `${field.name}`,
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id,
      hasBindings,
      group: "Icons",
    });
  }

  function addComponentInputsEditor(
    id: string,
    labelId: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const control = {
      Typename: "List",
      GridHeight: "250px",
      RowsPerPage: 10,
      Id: id,
      UniqueName: id,
      Bindings: {
        Rows: "State.ReferenceComponentInputsAsGrid",
        Columns: "State.ReferenceComponentInputsAsGridColumns",
      },
      Toolbar: {
        ShowExport: false,
        ShowFilter: false,
      },
      SpreadsheetMode: true,
      SpreadsheetModeOptions: {
        Mode: "Excel",
        ShowAddColumnsButton: false,
        ShowAddRowsButton: false,
      },
      Pagination: false,
      ShowFooter: false,
      HideFooterPagination: true,
      GridLayout: "normal",
      EditMode: "fullRow",
      Actions: [
        {
          Trigger: "onRowUpdate",
          CommandSet: {
            Id: "0x4sdf453",
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "NameValuePairToJSON",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      InputData: "Event.Rows",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      [`Form.${field.name}`]: "Event.TransformedData",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    } as List;

    addControl({ field, controls, control, layouts, id, hasBindings });
  }

  function addInputDataEditor(
    id: string,
    labelId: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const controlId = `${id}-code-editor`;

    const control = {
      Id: controlId,
      __typename: "CodeEditor",
      Label: splitCamelCase(field.name), // Or any other label you prefer
      Name: field.name,
      Bindings: {
        Value: `Form.${field.name}`,
      },
      MultiLine: true,
      NumberOfLines: 10,
      Height: "120px",
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id: controlId,
      hasBindings,
    });
  }
  function addEventDataEditor(
    id: string,
    labelId: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    const controlId = `${id}-code-editor`;

    const control = {
      Id: controlId,
      __typename: "CodeEditor",
      Label: splitCamelCase(field.name), // Or any other label you prefer
      Name: field.name,
      Bindings: {
        Value: `Form.${field.name}`,
      },
      MultiLine: true,
      NumberOfLines: 10,
      Height: "120px",
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
      ],
    };

    addControl({
      field,
      controls,
      control,
      layouts,
      id: controlId,
      hasBindings,
    });
  }
  function addSampleDataEditor(
    id: string,
    labelId: string,
    field: FieldDefinition,
    controls: Control[],
    layouts: LayoutsProps,
    hasBindings: boolean
  ) {
    // VS 2015-01-30: until we have a proper sample data editor, we will use the code editor
    addCodeEditor(id, field, controls, layouts, hasBindings);

    // const control = {
    //   __typename: "Button",
    //   Id: id,
    //   Name: field.name,
    //   Label: "Edit Sample Data",
    //   Style: { width: "150px !important" },
    //   Actions: [
    //     {
    //       Trigger: "onClick",
    //       CommandSet: {
    //         Id: "0x4453",
    //         FirstCommandId: "0",
    //         ExecuteCommandsInParallel: false,
    //         Commands: [
    //           {
    //             Id: "0",
    //             Instruction: {
    //               Name: "Transform",
    //             },
    //             Parameters: [
    //               {
    //                 Name: "TransformMethod",
    //                 Value: "JSONToNameValuePair",
    //               },
    //               {
    //                 Name: "Options",
    //                 Value: {
    //                   SingleRecord: false,
    //                 },
    //               },
    //               {
    //                 Name: "Bindings",
    //                 Value: {
    //                   InputData: `Form.${field.name}`,
    //                 },
    //               },
    //               {
    //                 Name: "DataTransformations",
    //                 Value: {
    //                   "State.SampleDataAsGrid": "Event.TransformedData.Rows",
    //                   "State.SampleDataGridColumns": "Event.TransformedData.Columns",
    //                 },
    //               },
    //             ],
    //             NextCommandIdOnSuccess: "1",
    //           },
    //           {
    //             Id: "1",
    //             Instruction: {
    //               Name: "SetState",
    //             },
    //             Parameters: [
    //               {
    //                 Name: "Bindings",
    //                 Value: {
    //                   "State.PopupTypename": "State.Typename",
    //                   "State.SampleDataVariables": `keys(Form.${field.name})`,
    //                   "State.SampleData": `Form.${field.name}`,
    //                   "State.SampleDataVariableType": "typeUseRows(State.Typename) ? 'MultipleRows' : 'SingleValue'",
    //                   "State.SampleDataGridColumns": `Form.ListColumns || Form.Columns`,
    //                   "State.SampleDataAsGrid": `Form.${field.name}`,
    //                   "State.SampleDataTransformMethod": "typeUseRows(State.Typename) ? '' : 'JSONToNameValuePair'",
    //                   // "State.PopupSelectedFormat": `Form.${field.name}`,
    //                 },
    //               },
    //             ],
    //             NextCommandIdOnSuccess: "2",
    //           },
    //           {
    //             Id: "2",
    //             Instruction: {
    //               Name: "OpenModalPopup",
    //             },
    //             // open modal to edit grid sample data
    //             Parameters: [
    //               { Name: "Title", Value: "Edit Sample Data" },
    //               { Name: "ModalPopupId", Value: "EditSampleDataPopup" },
    //             ],
    //             NextCommand: "CommandResult=='Save'?'3':null",
    //             //NextCommandIdOnSuccess: "3",
    //           },
    //           {
    //             Id: "3",
    //             Instruction: {
    //               Name: "SetState",
    //             },
    //             Parameters: [
    //               {
    //                 Name: "Bindings",
    //                 Value: {
    //                   [`Form.${field.name}`]: "State.SampleData",
    //                 },
    //               },
    //             ],
    //             NextCommandIdOnSuccess: "4",
    //           },
    //           {
    //             Id: "4",
    //             Instruction: {
    //               Name: "TriggerAction",
    //             },
    //             Parameters: [
    //               {
    //                 Name: "Trigger",
    //                 Value: "onSave",
    //               },
    //               {
    //                 Name: "TargetId",
    //                 Value: "PropertiesEditor/A/1",
    //               },
    //               {
    //                 Name: "Form",
    //                 Value: formId,
    //               }, //,
    //               // {
    //               //   Name: "Bindings",
    //               //   Value: {
    //               //     Event: "Form.Items",
    //               //   },
    //               // },
    //             ],
    //           },
    //         ],
    //       },
    //     },
    //     {
    //       Trigger: "onChange",
    //       CommandSet: {},
    //     },
    //   ],
    //   Classes: ["prop-editor-action-button"],
    // };

    // const idInputField = `${id}_if`;

    // const inputField = {
    //   Visible: false,
    //   __typename: "CodeEditor",
    //   Id: idInputField,
    //   Name: field.name, // Type: '${field.type}'
    //   Style: {
    //     height: "100px",
    //   },
    //   // Bindings: {
    //   //   Value: "State.SampleData",
    //   // },
    // } as CodeEditor;

    // addControl(labelId, field, controls, inputField, layouts, idInputField, false);

    // addControl({ field, controls, control, layouts, id, hasBindings });

    //"State.SampleData"
  }

  function addGridEditor(controls: Control[], layouts: LayoutsProps, parentId: string) {
    const control = {
      Id: `${parentId}-grid-editor`,
      __typename: "GridEditor",
      Label: "Columns",
      Name: "ListColumns",
      Bindings: {
        Data: "State.GridEditor.State",
        SelectedIndex: "State.GridEditor.DataIndex",
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {},
        },
        {
          Trigger: "onSelectColumn",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.GridEditor": "{ Data: null, State: null, DataIndex: null }",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "ClearForm",
                },
                Parameters: [
                  { Name: "Form", Value: "grid-column-prop-editor" },
                  { Name: "Purge", Value: true },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.GridEditor": `{ Data: Event.Column, State: Event.Column, DataIndex: Event.Index, Parent: '${config.Id}'}`,
                    },
                  },
                ],
              },
            ],
          },
        },
        6,
      ],
    };
    const controlButton = {
      Id: `${parentId}-configuration-grid-button`,
      __typename: "Button",
      Label: "Save Grid Configuration",
      Classes: ["button-tertiary-large"],
      Name: " ",
      Style: { width: "100%" },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SendMessageToParent",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      Page: "State.Page",
                      Type: "'onPageUpdate'",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    } as Button;

    addControl({
      field: {
        name: control.Name,
        type: "GridEditor",
      },
      controls,
      control,
      layouts,
      id: control.Id,
      hasBindings: false,
      group: "Grid Editor",
    });
    addControl({
      field: {
        name: control.Name,
        type: "GridEditor",
      },
      controls,
      control: controlButton,
      layouts,
      id: controlButton.Id,
      hasBindings: false,
      group: "Grid Editor",
    });
  }

  function addLabel(labelId: string, fieldName: string, controls: Control[]) {
    controls.push({
      __typename: "Label",
      Id: labelId,
      DefaultValue: fieldName,
    });
  }

  function getFieldProps(fieldName: string) {
    if (_cacheFields[fieldName]) return _cacheFields[fieldName];
    _cacheFields[fieldName] = fieldDefaults.find((x) => x?.FieldName.toLowerCase() == fieldName.toLowerCase());

    return _cacheFields[fieldName];
  }

  function addFields(
    fields: FieldDefinition[],
    parentId: string,
    controls: Control[],
    layouts: LayoutsProps,
    bindingFields: FieldDefinition[],
    data: any | null,
    ViewMode: string,
    showBindingField?: boolean | null,
    hideIndividualBindings?: Maybe<boolean>,
    parentPropName?: string
  ) {
    const i = 0;

    // Get the sort order
    const sortOrder = {};
    fields?.forEach((field) => {
      if (fieldsToIgnore.includes(field.name)) return;
      const defaultProps = getFieldProps(field.name);

      function getPropsFromDirectives(field: FieldDefinition) {
        const o: any = {};
        if (!isNil(field.directives?.group)) o.Group = field.directives?.group;

        if (!isNil(field.directives?.editorProps?.sequence)) o.Sequence = field.directives?.editorProps?.sequence;

        return o;
        // return {
        //   Group: field.directives?.group,
        //   Sequence: field.directives?.sequence,
        // };
      }

      const props = { ...defaultProps, ...getPropsFromDirectives(field) };

      if (isNil(props)) {
        sortOrder[field.name] = 45 * 100000 + 10000;
        return;
      }

      const seq = (fieldPropsGroups[props.Group] ?? 45) * 100000 + (10000 - (props.Sequence ?? 10000));

      sortOrder[field.name] = seq;
    });

    fields
      ?.sort((a, b) => (sortOrder[a.name] ?? 0) - (sortOrder[b.name] ?? 0))
      ?.forEach((field) => {
        // if (!data) return;
        if (field.directives?.editorProps?.groupProp === "Always") {
          const types: TypeWithProperties = local.FieldTypes.value;
          const type = { ...types[field.type] };
          const propFields = type.fields.map((f) => ({ ...f, group: field.name, name: `${field.name}.${f.name}` }));
          const propData = data[field.name];

          addFields(
            propFields,
            parentId,
            controls,
            layouts,
            bindingFields,
            propData,
            ViewMode,
            showBindingField,
            hideIndividualBindings,
            field.name
          );
          return;
        }
        if (
          fieldsToIgnore.includes(field.name) &&
          (field.name !== "Bindings" || (field.name === "Bindings" && !showBindingField))
        )
          return;

        if (layoutsToIgnore.includes(data?.__typename) && fieldsToIgnoreForLayout.includes(field.name)) return;

        const defaultProps = getFieldProps(field.name);
        let skipField = false;

        if (!isNil(defaultProps)) {
          if (isNullOrEmpty(field.description)) field.description = defaultProps.Description;
          if (isNil(field.sequence)) field.sequence = defaultProps.Sequence;

          if (field.directives?.editorProps?.mode == "Never") skipField = true;
          else if (field.directives?.editorProps?.mode == "Always") skipField = false;
          else if (defaultProps.NeverMode?.toLowerCase() === "yes" && isNil(field.directives?.editorProps?.mode))
            skipField = true;
          else if (defaultProps.AlwaysMode?.toLowerCase() === "yes" && isNil(field.directives?.editorProps?.mode))
            skipField = false;
          else if (isNullOrEmpty(ViewMode) || ViewMode === "All") skipField = false;
          else if (field.directives?.editorProps?.mode == ViewMode) skipField = false;
          else if (defaultProps[`${ViewMode}Mode`]?.toLowerCase() === "yes") skipField = false;
        }

        if (skipField) return;

        const fieldType = field.type.toString();
        const fieldId = `${parentId}/F/${field.name}`;
        const labelId = `${parentId}/L/${field.name}`;
        const hasBindings = bindingFields?.some((f) => f.name === field.name) && !hideIndividualBindings;

        if (field.name == "Style" || field.name.includes(".Style")) {
          addStyleEditor(controls, layouts, data, parentId, hasBindings, parentPropName);
        } else if (field.name === "AccordionContainerStyle") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "ClassSet") {
          addMultiSelectForClassSet(fieldId, field, controls, layouts, hasBindings);
        } else if (field.name === "ChartMetadata") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "FormatName") {
          addMultiSelectForFormatName(fieldId, labelId, field, parentId, controls, layouts, hasBindings); //
        } else if (fieldType === "StyleProperties" || fieldType === "StylePropertiesDictionary") {
          // Special handling for "Style" field with type "StyleProperties"
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (field.name === "Services" || fieldType === "[Service]" || fieldType === "Service") {
          // Special handling for "Style" field with type "StyleProperties"
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
          // } else if (fieldType === "Markdown") {
          //   addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (field.name === "State") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (field.name === "Actions" || fieldType === "[IActionTrigger]") {
          addActionEditor(controls, layouts, parentId, hasBindings);
        } else if (field.name === "ModalPopups" || fieldType === "[Dialog]") {
          addModalPopupsEditor(fieldId, field, controls, layouts, hasBindings, parentId);
        } else if (field.name == "ListColumns") {
          addGridEditor(controls, layouts, parentId);
        } else if (fieldType === "ChartMetadata") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "ContextAutocomplete") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (field.name === "CellLayout") {
          addCellLayoutEditor(controls, layouts, parentId, hasBindings);
          // addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType == "[MultiSelectObject]") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "Icon") {
          addIconSelector(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "Button") {
          addButtonSelector(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "SampleData") {
          addSampleDataEditor(fieldId, labelId, field, controls, layouts, hasBindings);
        } else if (fieldType === "DateTime" || field?.format === "date") {
          addDatePicker(fieldId, labelId, field, parentId, controls, layouts, hasBindings);
        } else if (fieldType === "InputData") {
          addInputDataEditor(fieldId, labelId, field, controls, layouts, hasBindings);
        } else if (fieldType === "EventData") {
          addEventDataEditor(fieldId, labelId, field, controls, layouts, hasBindings);
        } else if (fieldType === "InputBinding") {
          addComponentInputsEditor(fieldId, labelId, field, controls, layouts, hasBindings);
        } else if (fieldType === "TagSet" || fieldType.replace(/!/g, "") === "[String]") {
          addChipEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (field.directives?.recommendedValues) {
          addMultiSelect(fieldId, field, controls, layouts, hasBindings);
        } else if (fieldType === "Grouping") {
          addPropertiesEditor(fieldId, field, controls, layouts, hasBindings);
        } else if (field.type === "BindingsDictionary") {
          addBindingsEditor(fieldId, field, controls, layouts);
        } else if (field.name === "Bindings") {
          addTypedBindingsEditor(fieldId, field, controls, layouts, field.type);
        } else if (fieldType.toLocaleLowerCase() in fieldRenderFunctions) {
          fieldRenderFunctions[fieldType.toLocaleLowerCase()](
            fieldId,
            labelId,
            field,
            parentId,
            controls,
            layouts,
            hasBindings
          );
        } else if (fieldType == "Object") {
          addCodeEditor(fieldId, field, controls, layouts, hasBindings); //
        } else {
          // Fallback to addInputField if    no   specific    render     functionexists
          addInputField(fieldId, labelId, field, parentId, controls, layouts, hasBindings);
        }
      });

    // Handle binding fields that do not have an equivalent in the main type
    bindingFields?.forEach((bindingField) => {
      if (
        fields.some((field) => field.name === bindingField.name) ||
        (layoutsToIgnore.includes(data.__typename) && fieldsToIgnoreForLayout.includes(bindingField.name))
      )
        return;
      /* const layoutItemProps: string[] = []; */
      const fieldId = `${parentId}/F/${bindingField.name}_binding`;
      const labelId = `${parentId}/L/${bindingField.name}_binding`;

      addInputField(
        fieldId,
        labelId,
        bindingField,
        parentId,
        controls,
        layouts,
        false,
        `'${splitCamelCase(bindingField.name)}' binding expression`
      );
    });
  }

  // TK-TODO: Fix types
  function addLayoutForFields(
    innerLayouts: LayoutsProps,
    layouts: any[],
    componentData: any,
    config: PropertiesEditor
  ) {
    let i = 0;
    for (const key in innerLayouts) {
      // eslint-disable-next-line no-prototype-builtins
      if (innerLayouts.hasOwnProperty(key) /* && key !== "Value" */) {
        const layoutItems: any[] = [];
        const { ids: layoutsIds, classes, bindingFirst } = innerLayouts[key];

        layoutsIds.forEach((ids) => {
          const { leftLayout, rightLayout, bindingLayout } = ids;

          const fieldsLayoutContainer = {
            __typename: "FlexLayout",
            Id: `${i++}-rightLayoutContainer`,
            Direction: "row",
            Items: rightLayout,
            JustifyContent: key === "Buttons" ? "start" : "end",
            AlignItems: "center",
            Width: config.FieldWidth,
            Classes: ["prop-editor-fields-layout", classes ?? ""],
          } as FlexLayout;

          const bindingsLayoutContainer = {
            __typename: "FlexLayout",
            Id: `${i++}-bindingsLayoutContainer`,
            Direction: "row",
            Items: bindingLayout,
            JustifyContent: key === "Buttons" ? "start" : "end",
            Classes: ["prop-editor-bindings-layout"],
          } as FlexLayout;

          const rightLayouts = bindingLayout?.length
            ? ({
                __typename: "FlexLayout",
                Id: `${i++}-fieldsLayoutContainer`,
                Direction: "column",
                Items: bindingFirst
                  ? [bindingsLayoutContainer, fieldsLayoutContainer]
                  : [fieldsLayoutContainer, bindingsLayoutContainer],
                JustifyContent: key === "Buttons" ? "start" : "end",
                Style: { flex: 1 },
                Classes: ["prop-editor-right-layouts"],
              } as FlexLayout)
            : fieldsLayoutContainer;

          // const hasCodeEditor = rightLayout?.some(
          //   (x) => typeof x === "string" && x.includes("code-editor")
          // );
          const hasCodeEditor = rightLayout?.some((x) => typeof x === "string" && x.includes("code-editor"));

          if (leftLayout?.length) {
            const leftLayoutContainer = {
              __typename: "FlexLayout",
              Id: `${i++}-leftLayoutContainer`,
              Direction: "row",
              Items: leftLayout,
              Classes: ["prop-editor-left-layout"],
              Width: config.LabelWidth,
            } as FlexLayout;

            layoutItems.push({
              __typename: "FlexLayout",
              Id: `${i++}-layoutsContainer`,
              Direction: config.isSubComponent ? "row" : "column",
              Spacing: "2px",
              Items: [leftLayoutContainer, rightLayouts],
              Classes: ["prop-editor-layouts-container"],
            } as FlexLayout);
          } else {
            layoutItems.push(rightLayouts);
          }
        });

        if (innerLayouts[key].layoutType === "AccordionLayout") {
          // TK-TODO: Fix types
          layouts.push({
            __typename: "AccordionLayout",
            Classes: ["properties-editor-accordion"],
            Id: `${componentData?.Id}-${i++}`,
            Groups: [
              {
                Id: key,
                Label: key,
                Items: layoutItems,
                Expanded: true,
              },
            ],
          } as AccordionLayout);
        } else {
          layouts.push({
            __typename: config.WrapperLayoutType ?? "FlexLayout",
            Id: `${componentData?.Id}-${i++}`,
            Direction: "row",
            Classes: [innerLayouts[key]?.classes, "prop-editor-base-layout"],
            Items: layoutItems, //layoutIds
          } as FlexLayout);
        }
      }
    }
  }

  interface StringArrayDictionary {
    [key: string]: { ids: string[][]; layoutType?: string };
  }

  function getBindingFields(types: TypeWithProperties, fields: FieldDefinition[]): FieldDefinition[] {
    const bindingsEntry = fields?.find((entry) => entry.name === "Bindings") ?? null;

    if (isNil(bindingsEntry)) return [];

    return types[bindingsEntry.type]?.fields;
  }

  function createForm(
    data: any,
    config: PropertiesEditor,
    ViewMode: string,
    SearchPropInput: string,
    local: any
  ): Form {
    const types: TypeWithProperties = local.FieldTypes.value;

    if (isNil(data) && !isNullOrEmpty(config.DefaultTypeName)) data = { __typename: config.DefaultTypeName };

    const typeName = data["__typename"] ?? data["Typename"] ?? data.Name ?? config.__typename;

    if (isNil(typeName) || isNullOrEmpty(typeName)) {
      if (isNullOrEmpty(config.DefaultTypeName)) throw new Error("Cannot create form: no typename in data");
      else data["__typename"] = config.DefaultTypeName;
    }

    const type = { ...types[typeName] };
    if (SearchPropInput) {
      // search for each word written in the search input separated by space
      const searchArray = SearchPropInput?.toLowerCase()
        ?.split(" ")
        ?.filter((search) => !!search);
      type.fields = type.fields.filter((field) =>
        searchArray.some(
          (term) =>
            field.name.toLowerCase().includes(term) ||
            field.type.includes(term) ||
            ((Object.keys(data?.[field.name] ?? {}) as string[]) || []).some((value) => value.includes(term))
        )
      );
    }

    if (isNil(type)) {
      throw new Error("Cannot create form: no type found for typename " + typeName);
    }

    const controls: Control[] = [];
    const innerLayouts: LayoutsProps = {};
    const layouts: FlexLayout[] = [];
    const bindingFields = getBindingFields(types, type.fields);

    //   <Stack direction="row" justifyContent="flex-end" spacing={0} key={`${key}_stack`}>
    //   {content.HeaderItems?.map((item, key) => {
    //     (item as any).ContextId = content.Id;
    //     return <NGLayoutItem config={item} key={key} context={context} />;
    //   })}

    //   {content.SharingOptions?.CanShare && (
    //     <NGShareDialog config={shareDialog} key={`${key}_SO`} context={context} />
    //   )}
    // </Stack>

    // We translate the onSave actions from the props editor configuration to the onClick actions of the save button
    if (config.AddAncillaryButtons) addAncillaryButtons(typeName, config, controls, innerLayouts, local);

    addFields(
      type.fields,
      formId,
      controls,
      innerLayouts,
      bindingFields,
      data,
      ViewMode,
      config.ShowBindingField,
      config.HideIndividualBindings
    );
    addLayoutForFields(innerLayouts, layouts, local.Data.value, config);

    const onSave = config.Actions?.find((action) => action?.Trigger === "onSave"); // Filter to only include onSave triggers
    if (onSave) onSave.ListenTo = ["onChange", "onRowUpdate", "onCellUpdate"];
    /* const formActions = [
      ...(config.Actions?.filter((action) => action?.Trigger !== "onSave") ?? []), // Filter to exclude onSave triggers
      { ...onSave, Trigger: "onChildChange" },
      { ...onSave, Trigger: "onChildRowUpdate" },
    ]; */
    ///.map((action) => ({ ...action, Trigger: "onChildChange" })); // Map to rename the trigger

    const form: Form = {
      __typename: "Form",
      UniqueName: formId,
      Id: formId,
      Visible: true,
      Data: config.Data,
      Bindings: {
        Visible: config.Bindings?.Visible ?? "State.PropertiesEditorTypeName",
        Data: !config.Data ? config.Bindings?.Data ?? "State.PropertiesEditorData" : undefined,
      },
      Items: controls as unknown as LayoutItem[],
      Layout: {
        __typename: "FlexLayout",
        Id: `pf_${formId}/LLY`,
        Classes: ["prop-editor-layout"],
        Style: config.Style,
        Direction: "column",
        Items: layouts,
      } as FlexLayout,
      Actions: config.Actions,
      StopPropagation: true,
    };

    return form;
  }

  function addAncillaryButtons(
    typeName: string,
    config: PropertiesEditor,
    controls: Control[],
    innerLayouts: LayoutsProps,
    local: any
  ) {
    const layoutItemProps: string[] = [];
    const componentId = local.Data.value?.Id;
    addFormActionButton(config, "onSave", controls, "saveButton", "SaveOutlined", layoutItemProps);

    // We translate the onDelete actions from the props editor configuration to the onClick actions of the delete button
    const deleteBinding = {
      Disabled: `Form.BlockDelete`,
    };
    if (readOnlyTypes.indexOf(local.Data.value.__typename as string) < 0)
      addFormActionButton(
        config,
        "onDelete",
        controls,
        "deleteButton",
        "DeleteOutlined",
        layoutItemProps,
        deleteBinding
      );

    const stateButton: Button = {
      __typename: "Button",
      Id: "StateShowButton",
      Label: "Inspect State",
      Classes: ["button-tertiary-large"],
      StartIcon: {
        IconName: "Adjust",
      },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              //
              {
                Id: "0",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "NGExtractStateVariables",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      InputData: "State.SelectedItem",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.AllStateVariablesInComponent": "Event.TransformedData",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "OpenContextMenu",
                },
                Parameters: [
                  {
                    Name: "ContextMenuId",
                    Value: "contextMenuForState",
                  },
                  {
                    Name: "Form",
                    Value: formId,
                  },
                ],
              },
            ],
          },
        },
      ],
    };

    const toggleExpandedButton: ButtonGroup = {
      __typename: "ButtonGroup",
      Id: "ToggleExpandedButton",
      Orientation: "vertical",
      Exclusive: true,
      MultiSelectPossibleValues: [
        {
          Value: true,
          IconName: "ExpandMoreOutlined",
        },
        {
          Value: false,
          IconName: "ExpandLessOutlined",
        },
      ],
      Style: {
        padding: "0",
        "&.MuiButtonBase-root.Mui-selected": {
          color: "rgba(50, 50, 50, 1)",
          backgroundColor: "#EFEFEF",
        },
      },
      ValueExpression: "Value",
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      ["State.ToggleExpanded['" + componentId + "']"]: "Event.Value",
                      ["State.NGAccordion['" + componentId + "'].Expanded"]: "{}",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    };

    const searchInput: InputField = {
      __typename: "InputField",
      Id: "SearchInput",
      Placeholder: "Search props...",
      Style: { padding: "0 6px", marginTop: "0", height: "auto" },
      Size: "small",
      FullWidth: false,
      // FocusOnDisplay: true,
      DefaultValue: local.SearchPropInput.value,
      Adornment: {
        Position: "start",
        Icon: { IconName: "Search" },
      },
      Actions: [
        {
          Trigger: "onChangeCustom",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.PropertiesEditorState.SearchPropInput": "Event",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    };

    (contextMenuForState as any).ContextId = config.Id;
    controls.push(contextMenuForState as any);

    [stateButton, searchInput, toggleExpandedButton].forEach((item, key) => {
      (item as any).ContextId = config.Id;
      controls.push(item as any);
      layoutItemProps.push((item as any).Id);
    });

    local.HeaderItems.value?.forEach((item, key) => {
      (item as any).ContextId = config.Id;
      controls.push(item as any);
      layoutItemProps.push((item as any).Id);
    });

    if (headerButtonsByType[typeName]) {
      headerButtonsByType[typeName].forEach((item) => {
        (item as any).ContextId = config.Id;
        controls.push(item as any);
        layoutItemProps.push((item as any).Id);
      });
    }

    innerLayouts["Buttons"] = {
      ids: [{ rightLayout: layoutItemProps }],
      classes: "prop-editor-action-buttons-header",
    };
  }
});

export default NGPropertiesEditor;
