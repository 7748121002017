import {
  Dialog as MUIDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  DialogActions,
  Box,
  Paper,
} from "@mui/material";
import { ResponsivePropertyObject, SimpleContainer } from "../../../resolvers-types";
import { INGDialogProps } from "../../library/NGFieldExtensions";
import { clearModalComponentState, setupHandlers, setupLocalState, updateItemContext } from "../../library/dataService";
import { batch, signal, useSignal, useSignalEffect } from "@preact/signals-react";
import { isNil } from "lodash";
import NGSimpleContainer from "../../generators/NGSimpleContainer";
import {
  getClassName,
  getCustomLabel,
  getResponsiveObject,
  getStyleObject,
  getTestId,
  getsxObject,
  isNullOrEmpty,
  toBoolean,
} from "../../library/utils";
import CloseIcon from "@mui/icons-material/Close";
import NGLayoutItem from "../../generators/NGLayoutItem";
import Draggable from "react-draggable";
import { isObject, isString } from "highcharts";
import { isBoolean } from "lodash-es";

export default function NGModalPopup({ config, context }: INGDialogProps) {
  const currentContext = updateItemContext(context, config);

  const local = setupLocalState(
    config,
    {
      Open: useSignal(config.Open ?? false),
      ContentText: useSignal(config.ContentText ?? ""),
      ContentClasses: useSignal(config.ContentClasses ?? []),
      ContentStyle: useSignal(config.ContentStyle ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Style: useSignal(config.Style ?? {}),
      Title: useSignal(config.Title),
      ShowOkButton: useSignal(config.ShowOkButton),
      ShowCancelButton: useSignal(config.ShowCancelButton),
      ShowCloseButton: useSignal(config.ShowCloseButton),
      ButtonClicked: useSignal(config.ButtonClicked ?? "None"),
      TitleStyle: useSignal(config.TitleStyle ?? {}),
      FullCustom: useSignal(config.FullCustom ?? false),
      OnClose: null,
    },
    currentContext
  );
  const handlers = setupHandlers(config, currentContext);

  useSignalEffect(() => {
    // if the modal is being closed, auto clear its form so that it doesn't retain old state
    if (!local.Open.value) {
      clearModalComponentState(currentContext, config.Id);
    }
  });

  const closeModal = () => {
    local.Open.value = false; // TODO: do we always have to modify the whole object??
    if (!isNil(local.OnClose)) {
      console.log("onClose", local.ButtonClicked.value);
      local.OnClose();
    }
    if (handlers["onClose"]) {
      handlers["onClose"](config, config);
    }
  };

  const onClose = () => {
    batch(() => {
      local.ButtonClicked.value = "Close";
      closeModal();
    });
  };

  (config.ContentContainer as any).ContextId = (config.ContentContainer as any).ContextId ?? (config as any).ContextId;

  const titleId = "alert-dialog-title"; //getTestId(config, "DialogTitle");

  function PaperComponent(props: React.HTMLProps<HTMLDivElement>) {
    return (
      // <Draggable handle={`#${titleId}`} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper sx={getStyleObject(config.Style)} classes={config.Classes?.join(" ")} {...props} />
      // </Draggable>
    );
  }

  let fullScreen = false;
  if ((!isNil(config.FullScreen) && isString(config.FullScreen)) || isBoolean(config.FullScreen)) {
    fullScreen = toBoolean(config.FullScreen as any);
  } else if (isObject(config.FullScreen)) {
    const o = getResponsiveObject(config.FullScreen as ResponsivePropertyObject);
  }

  return (
    <>
      {config.FullCustom ? (
        <MUIDialog
          data-testid={getTestId(config)}
          open={local.Open.value}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={fullScreen}
        >
          <DialogContent sx={{ padding: 0 }}>
            {config.ContentContainer && (
              <NGSimpleContainer
                data-testid={getTestId(config, "DialogContent")}
                config={config.ContentContainer as SimpleContainer}
                context={currentContext}
              />
            )}
          </DialogContent>
        </MUIDialog>
      ) : (
        <MUIDialog
          data-testid={getTestId(config)}
          open={local.Open.value}
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={fullScreen}
          className={getClassName(local.Classes)}
          sx={getsxObject(local.Style.value)}
        >
          <IconButton
            data-testid={getTestId(config, "close")}
            onClick={onClose}
            style={{ position: "absolute", right: 8, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id={titleId} data-testid={getTestId(config, "DialogTitle")} sx={local.TitleStyle.value}>
            <Box display="flex" justifyContent="center">
              {!isNullOrEmpty(local.Title.value) && getCustomLabel(local.Title.value)}
              {!isNil(config.HeaderItem) && <NGLayoutItem config={config.HeaderItem} context={currentContext} />}
            </Box>
          </DialogTitle>
          <DialogContent className={getClassName(local.ContentClasses)} sx={getsxObject(local.ContentStyle.value)}>
            {local.ContentText.value && (
              <DialogContentText id="alert-dialog-description" data-testid={getTestId(config, "DialogContextText")}>
                {local.ContentText.value}
              </DialogContentText>
            )}
            {config.ContentContainer && (
              <NGSimpleContainer
                data-testid={getTestId(config, "DialogContent")}
                config={config.ContentContainer as SimpleContainer}
                context={currentContext}
              />
            )}
          </DialogContent>
          {config?.FooterContainer && (
            <DialogActions>
              <NGSimpleContainer
                data-testid={getTestId(config, "DialogFooter")}
                config={config.FooterContainer as SimpleContainer}
                context={currentContext}
              />
            </DialogActions>
          )}
        </MUIDialog>
      )}
    </>
  );
}
