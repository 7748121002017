import { INGSimpleContainerProps } from "../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import { effect, useSignal } from "@preact/signals-react";
import { setupForm, setupHandlers, setupLocalState, updateItemContext } from "../library/dataService";
import { generateUID, getClassName, getTestId } from "../library/utils";
import NGLayout from "../layouts/NGLayout";
import NGReviewDialog from "../components/NGReviewDialog/NGReviewDialog";
import { containerWithPaper } from "./GeneratorUtils";
import { Stack } from "@mui/material";
import NGLayoutItem from "./NGLayoutItem";
import useResizable from "../hooks/useResizable";

export default function NGSimpleContainer({ config, context, children }: INGSimpleContainerProps) {
  const isForm = config.CreateForm || config.Data || config.Bindings?.Data;
  const currentContext = updateItemContext(context, config, { IsForm: isForm });

  const local = setupLocalState(
    config,
    {
      Data: useSignal(config.Data ?? {}),
      Visible: useSignal(config.Visible ?? true),
      Resizable: useSignal(config.Resizable ?? false),
      InDesignMode: useSignal(config.InDesignMode ?? false),
      InReviewMode: useSignal(config.InReviewMode ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      CardType: useSignal(config.CardType ?? "None"),
    },
    currentContext
  );


  // handle clear (x button) cases
  effect(() => {
    if (config.CardType !== local.CardType.value) {
      local.CardType.value = config.CardType;
    }
  });

  // AA-TODO: Setup form if container is a form
  if (isForm) {
    currentContext.Form = { Data: local.Data, Config: config };
    setupForm(currentContext);
  }

  if (!config.IgnoreLayout && !config.Layout) {
    config.Layout = {
      __typename: "FlexLayout",
      Id: generateUID(),
    };
  }

  if (!isNil(config.ReviewDialogOptions)) {
    (config.ReviewDialogOptions as any).ContextId = (config as any).ContextId;
  }

  const handlers = setupHandlers(config, currentContext);

  const { resizable } = useResizable(local.Resizable.value);

  function renderContainer(addTestId: boolean) {

    // these are classes that should exist on theme
    const cardSx = {
      Outlined: 'card-outlined',
      Elevated: 'card-elevated',
      Filled: 'card-filled',
      None: '',
    };

    return (
      <>
        {config.IgnoreLayout &&
          resizable(
            <Stack
              data-testid={addTestId ? getTestId(config) : undefined}
              data-type={addTestId ? config.__typename : undefined}
              sx={local.Style.value}
              className={`${getClassName(local.Classes)} ${cardSx[local.CardType.value]}`}
              {...handlers}
            >
              {config.Items?.map((item) => (
                <NGLayoutItem key={(item as any).Id} config={item} context={currentContext} />
              ))}
              {children}
              {local.InReviewMode.value && (
                <NGReviewDialog config={config.ReviewDialogOptions ?? {}} context={currentContext}></NGReviewDialog>
              )}
            </Stack>
          )}
        {!config.IgnoreLayout &&
          config.Layout &&
          resizable(
            <NGLayout
              layout={config.Layout}
              layoutItem={config}
              inDesignMode={local.InDesignMode.value}
              context={currentContext}
            />
          )}
        {!config.IgnoreLayout &&
          local.InReviewMode.value &&
          resizable(
            <NGReviewDialog config={config.ReviewDialogOptions ?? {}} context={currentContext}></NGReviewDialog>
          )}
      </>
    );
  }

  return containerWithPaper(local, config, renderContainer, context);
}
